

























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "../BudgetView.scss";
import { Ax } from "@/utils";
import JournalTable from "@/modules/budget/components/Journal.vue";
import store from "@/services/store";

@Component({
    components: { JournalTable }
})
export default class PdfLoader520 extends Vue {
    @Prop({ type: Object, required: true }) propData!: null;
    @Prop({ type: [], required: true })  fileBody!: null;
    @Prop({ type: String })  fileName!: string;
    @Prop({ type: File })  file!: File;

    light: string = "light";
    fields = [
        { key: "transact_nom", label: "No транзакции", sortable: false },
        { key: "bik", label: "БИК", sortable: false },
        { key: "iik", label: "ИИК", sortable: false },
        { key: "debit", label: "Дебет", sortable: false },
        { key: "credit", label: "Кредит", sortable: false },
    ];

    get userLogin(): string {
        return this.$store.state.user.login;
    }

    async CheckHash(): Promise<void> {
        if (!this.file || !this.fileName) return;

        const formData = new FormData();
        formData.append("file", this.file, this.fileName);

        try {
            const response = await fetch("/api-py/check-hash", {
                method: "POST",
                body: formData
            });

            const result = await response.json();
            console.debug(result);

            if (result === true) {
                this.handleOk();
            } else {
                this.on520Load(formData);
            }
        } catch (error) {
            console.error("Error checking hash:", error);
        }
    }

    handleOk(): void {
        if (!this.file || !this.fileName) return;

        const formData = new FormData();
        formData.append("file", this.file, this.fileName);

        this.$bvModal
            .msgBoxConfirm("Сохранение приведет к изменению ранее принятых данных", {
                title: "Подтвердите",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Сохранить",
                cancelTitle: "Отменить",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then((value: boolean) => {
                console.log("Save", value);
                if (value) {
                    this.on520Load(formData);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    on520Load(formData: FormData): void {
        const user = this.userLogin;
        this.$emit("save-load520", true);

        Ax({
            url: `/api-py/save-b520/${user}`,
            method: "POST",
            data: formData
        }, data => {
            console.debug("Data", data)
        });

        this.makeToast("success", "Загрузка файла начата! Результат в журнале Загрузки", "Результат");
        this.$emit("save-load520", false);
    }

    onClear(): void {
        this.$emit("form-clear520", []);
        if (this.propData) {
            this.propData = null
        }
    }

    makeToast(variant: string, tostbody: string, title: string): void {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 2000,
            solid: true
        });
    }

    keyEvent(event: KeyboardEvent): void {
        // Обработчик событий клавиатуры (пока пуст)
    }
}

