<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg_name }}</span>
                <span class="item-breadcrumb" v-if="year !== null" >{{'Год'}} - {{ year }}</span>
                <span class="item-breadcrumb" v-if="month !== null" >{{ month }}</span>
            </div>
        </div>
        <svod-table v-if="sheet.code === 1" :params="params" ref="1" />
        <income-table v-if="sheet.code === 2" :params="params" ref="2" />
        <outcome-table v-if="sheet.code === 3" :params="params" ref="3" />
        <meeting-table v-if="sheet.code === 4" :params="params" ref="4" />
        <div class="actions-tab in-tab">
            <b-dropdown
                variant="link"
                right
                toggle-class="text-decoration-none"
                no-caret
            >
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep()">{{
                    repName
                }}</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';
import incomeTable from './application-form-budget-msu-income'
import outcomeTable from './application-form-budget-msu-outcome'
import svodTable from './application-form-budget-msu-svod'
import meetingTable from './application-form-budget-msu-meeting'

export default {
    name: "application-form-budget-msu",
    components: { svodTable, incomeTable, outcomeTable, meetingTable },
    data() {
        return {
            sheet: {text: 'Сводная', code: 1},
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            month: null,
            curDate: null,
            dateTo: null,
            repCode: 'budget-msu',
            repName: 'Сводная информация по бюджетам МСУ',
            loading: false,
            unReport: [],
            curTab: 0,
            params: null,
            headerHeight: '40px'
        };
    },

    async mounted() {
        await this.getAccessLevel();
        this.$watch('reg', () => {
            if (this.sheet.code !== 3 && this.reg.slice(2, 6) !== '0101') {
                this.$emit('reasonChanged', true);
            } else {
                this.$emit('reasonChanged', false);
            }
        })
    },
    created() {
        this.$on('budgetMsu', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month) => {
            const d = { reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month, report: false, headerHeight: this.headerHeight }
            this.sheet = sheet;
            this.reg = reg;
            if (reg.slice(2, 6) === '0101') {
                this.reg_name = obl + '0000 - ' + reg_name;
            } else {
                this.reg_name = reg + ' - ' + reg_name;
            }
            this.year = year;
            this.month = month;
            this.params = Object.assign(d);
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                this.curParams = {
                    region: this.params.reg,
                    date_to: moment(this.params.date_to).format('YYYY-MM-DD'),
                    year: this.params.year,
                    obl: this.params.obl,
                    uuid: this.params.uuid,
                    report: true,
                    update: false
                };
                Ax(
                    {
                        url: '/api-py/fulfill_report_budget_msu/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        repSave() {
            this.$refs[String(this.sheet.code)].saveClick();
        },
    }
}
</script>

<style scoped>
.td-numbering{
    padding: 3px 10px!important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400!important;
    color: #6087A0 !important;
    background-color: #F7F9FC;
}
</style>