var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table b-table table-bordered b-table-no-border-collapse sticky-header"},[_c('thead',{staticClass:"thead-light",style:(("position: sticky; top: " + _vm.headerHeight + ";"))},[_c('tr',[_c('b-th',{attrs:{"colspan":"4"}}),_c('b-th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("в том числе:")]),_c('b-th',{attrs:{"colspan":"1"}}),_c('b-th',{staticClass:"text-center",attrs:{"colspan":"1"}},[_vm._v("в том числе:")])],1),_c('tr',_vm._l((_vm.tableFields),function(header){return _c('th',{key:header.key},[(header.key === 'num')?[_c('b-button',{on:{"click":function($event){return _vm.openAll()}}},[(_vm.open)?_c('i',{staticClass:"icon icon-chevron-circle icon-rotate-180"}):_vm._e(),(!_vm.open)?_c('i',{staticClass:"icon icon-chevron-circle"}):_vm._e()])]:[_vm._v(" "+_vm._s(header.label)+" ")]],2)}),0)]),_c('tbody',[_c('tr',_vm._l((_vm.tableFields),function(header,index){return _c('td',{key:'header-' + header.key,staticClass:"td-numbering"},[(index !== 0)?_c('div',{staticClass:"text-center w-100px"},[_vm._v(" "+_vm._s(index)+" ")]):_vm._e()])}),0),_vm._l((_vm.unReport),function(item,index){return _c('tr',{key:(index + "_" + (item.name))},[(item.visible)?_vm._l((_vm.tableFields),function(header){return _c('td',{key:'body-' + header.key,staticStyle:{"padding":"0.75rem","font-size":"0.7777777778rem","font-weight":"500","line-height":"1.3","color":"#0d1a2e !important","text-transform":"none"},style:(item.region.endsWith('00')
                                    ? 'background-color: rgba(176, 224, 255, .5)!important;'
                                    : item.dataType === 2
                                    ? 'background-color: #d6d8db !important;'
                                    : '')},[(header.key === 'num')?[(item.region.endsWith('00'))?_c('b-button',{on:{"click":function($event){return _vm.openChilds(item)}},model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}},[(item.open)?_c('i',{staticClass:"icon icon-chevron-circle icon-rotate-180"}):_vm._e(),(!item.open)?_c('i',{staticClass:"icon icon-chevron-circle"}):_vm._e()]):_vm._e()]:_vm._e(),(header.key === 'name')?[(item.region.endsWith('00') || item.region.endsWith('01'))?_c('div',{staticClass:"text-left w-200px"},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',{staticClass:"text-left w-200px"},[_vm._v(" "+_vm._s(item.region)+" - "+_vm._s(item.name)+" ")])]:(header.key === 'outcomes')?[_c('div',[_vm._v(" "+_vm._s(_vm.$n(item.outcomes))+" ")])]:(header.key === 'count')?[(
                                        !_vm.reg.includes('0101') &&
                                        item.region.slice(4, 6) !== '00' && item.region.slice(4, 6) !== '01'
                                    )?_c('div',{staticClass:"w-150px"},[_c('b-form-input',{attrs:{"type":'number',"min":"0"},on:{"input":function($event){return _vm.updateData(item)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", _vm._n($$v))},expression:"item.count"}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.$n(item.count))+" ")])]:(header.key === 'incomes')?[_c('div',[_vm._v(" "+_vm._s(_vm.$n(item.incomes))+" ")])]:(header.key === 'withdrawals')?[_c('div',[_vm._v(" "+_vm._s(_vm.$n(item.withdrawals))+" ")])]:(header.key === 'subventions')?[_c('div',[_vm._v(" "+_vm._s(_vm.$n(item.subventions))+" ")])]:(header.key === 'percent')?[(item.subventions)?_c('div',[_vm._v(" "+_vm._s(_vm.$n(Math.round((item.subventions / item.incomes) * 1000) / 10))+" ")]):_c('div',[_vm._v(" 0 ")])]:_vm._e()],2)}):_vm._e()],2)})],2)])]),_c('div',[_c('loading',{attrs:{"active":_vm.loading,"is-full-screen":"","spinner":"bar-fade-scale","color":"#6495ED"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }