<template>
    <div>
        <div class="table-container">
            <!-- хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null" >{{ reg }} - {{ reg_name }}</span>
                <span class="item-breadcrumb" >{{ 'Дата' }} - {{ dateTo}}</span>
                <!-- <span class="item-breadcrumb" v-if="year !== null" >{{'Год'}} - {{ year }}</span> -->
                <!-- <span class="item-breadcrumb" v-if="qvartal !== null" >{{ qvartal}}</span> -->
            </div>
        </div>
                    <div class="table-container">
                        <table class="table b-table table-bordered b-table-no-border-collapse sticky-header">
                            <thead class="thead-light" :style="`position: sticky; top: ${headerHeight};`">
                                <template v-if="rb">
                                    <b-tr>
                                        <b-th rowspan="2" colspan="3"></b-th>
                                        <b-th rowspan="2" colspan="1">Всего</b-th>
                                        <b-th colspan="11" class="text-center">из них за счет :</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-th colspan="6" class="text-center">в т.ч.</b-th>
                                        <b-th colspan="5"></b-th>
                                    </b-tr>
                                </template>
                                <template v-else>
                                    <template v-if="type.code === 1">
                                        <b-tr>
                                            <b-th rowspan="2" colspan="4"></b-th>
                                            <b-th rowspan="2" colspan="5" class="text-center">в т.ч.</b-th>
                                            <b-th colspan="12" class="text-center">из них за счет :</b-th>
                                            <b-th rowspan="2" colspan="1"></b-th>
                                        </b-tr>
                                        <b-tr>
                                            <b-th colspan="2"></b-th>
                                            <b-th colspan="6" class="text-center">в т.ч.</b-th>
                                            <b-th colspan="4"></b-th>
                                        </b-tr>
                                    </template>
                                    <template v-if="type.code === 2">
                                        <b-tr>
                                            <b-th colspan="4"></b-th>
                                            <b-th colspan="5" class="text-center">в т.ч.</b-th>
                                            <b-th colspan="5" class="text-center">из них:</b-th>
                                            <b-th colspan="1"></b-th>
                                        </b-tr>
                                    </template>
                                </template>
                                <tr>
                                    <th v-for="header of tableFields" :key="header.key">
                                        <template v-if="header.key === 'num'">
                                            <b-button @click="openAll()">
                                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                                                <i class="icon icon-chevron-circle" v-if="!open"></i>
                                            </b-button>
                                        </template>
                                        <template v-else>
                                            {{ header.label }}
                                        </template>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(header, index) of tableFields" :key="'header-' + header.key" class="td-numbering">
                                        <div class="text-center w-100px" v-if="index !== 0 && header.key !== 'action'">
                                            {{ index }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="(item, index) of unReport" :key="`${index}_${item.dataType}_${item.name_ru}`">
                                    <template v-if="item.visible">
                                        <td v-for="header of tableFields" :key="'body-' + header.key" style="padding: .75rem; font-size: .7777777778rem; font-weight: 500; line-height: 1.3; color: #0d1a2e!important; text-transform: none;" :style="item.dataType === 1 ? 'background-color: rgba(176, 224, 255, .5)!important;' : item.dataType === 2 ? 'background-color: #d6d8db !important;' : ''">
                                            <template v-if="header.key === 'num'">
                                                <b-button v-if="item.dataType === 1 || item.dataType === 2"
                                                        @click="openChilds(item)" v-model="item.open">
                                                    <i class="icon icon-chevron-circle icon-rotate-180" v-if="item.open"></i>
                                                    <i class="icon icon-chevron-circle" v-if="!item.open"></i>
                                                </b-button>
                                            </template>
                                            <template v-else-if="header.key === 'name_ru'">
                                                <div class="text-left w-200px">
                                                    {{ item.name_ru }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'count'">
                                                <div class="w-150px row" v-if="item.abp && !reg.includes('0000')">
                                                    <div v-if="item.showErr" class="text-left error col-2">
                                                        <i class="icon icon-danger"></i>
                                                            <div class="pop-up">
                                                                <p class="red-text">Внимание! Заполните информацию по количеству штатных единиц </p>
                                                            </div>
                                                    </div>
                                                    <b-form-input style="min-width: 80% !important; width: 80% !important;" v-model.number="item.count" :type="'number'" @input="updateData(item)" min="0" :state="!item.showErr"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.count) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'salary'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.salary" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.salary) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'add_village'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.add_village" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.add_village) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'health'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.health" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.health) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'ecology'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.ecology" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.ecology) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'add_vac'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.add_vac" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.add_vac) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'add_health'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.add_health" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.add_health) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'tax_soc'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.tax_soc" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.tax_soc) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'tax_gfcc'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.tax_gfcc" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.tax_gfcc) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'tax_osms'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.tax_osms" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.tax_osms) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'pension_contrib'">
                                                <div class="w-150px" v-if="item.abp && !reg.includes('0000')">
                                                    <b-form-input v-model.number="item.pension_contrib" :type="'number'" @input="updateData(item)" min="0"></b-form-input>
                                                </div>
                                                <div v-else>
                                                    {{ $n(item.pension_contrib) }}
                                                </div>
                                            </template>
                                            <template v-else-if="header.key === 'action'">
                                                <div v-if="(item.dataType === 1 && !reg.includes('0000'))">
                                                    <b-dropdown dropleft class="more-dropdown">
                                                        <template v-slot:button-content>
                                                            <i class="icon icon-more"></i>
                                                        </template>
                                                        <template>
                                                            <b-dropdown-item @click="saveData(item)">
                                                                Сохранить
                                                            </b-dropdown-item>
                                                            <b-dropdown-item disabled>
                                                                Информация
                                                            </b-dropdown-item>
                                                        </template>
                                                    </b-dropdown>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="text-left">{{ $n(item[header.key]) }}</div>
                                            </template>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>


        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content >
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadRep()">{{repName}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <div>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
        </div>
</template>
<script>
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import { type } from '@amcharts/amcharts4/core';

export default {
    name: "application-form-fzp",
    components: {'loading': VueElementLoading},
    data() {
        return {
            obl: null,
            access_level: 1,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            qvartal: null,
            year: null,
            curDate: null,
            dateTo: null,
            repCode: 'FZP',
            repName: 'Отчет по ФЗП',
            type: {text: 'гражд.', code: 1},
            loading: false,
            new: false,
            rb: false,
            tableFields: [
                {
                    key: 'num',
                    label: '№'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'total_staff_count',
                    label: 'Кол-во штатных единиц'
                },
                {
                    key: 'total_calc',
                    label: 'ВСЕГО'
                },
                {
                    key: 'total_salary',
                    label: 'оплата труда'
                },
                {
                    key: 'total_soc_tax',
                    label: 'соцналог'
                },
                {
                    key: 'total_gfcc_tax',
                    label: 'на соц. отчислен. в ГФСС'
                },
                {
                    key: 'total_osms_tax',
                    label: 'отчис. обяз.соц.мед. страхов.'
                },
               {
                    key: 'total_pension_payment',
                    label: 'обязательные пенсионные взносы работодателя'
                },
                {
                    key: 'total_mb_salary',
                    label: 'МБ оплата труда (на дейсв. сеть)'
                },
                {
                    key: 'count',
                    label: 'кол-во штатных единиц'
                },
                {
                    key: 'salary',
                    label: 'зараб. плата (110 спец и по др. спец.по отплате труда)'
                },
                {
                    key: 'add_village',
                    label: '25% сельских надбавок'
                },
                {
                    key: 'health',
                    label: 'пособие на оздоровление (Трудовой Кодекс)'
                },
                {
                    key: 'ecology',
                    label: 'эколог.надбавки'
                },
                {
                    key: 'add_vac',
                    label: 'доп. отпуск (на реализ.ЗРК по Приаралью и Семей)'
                },
                {
                    key: 'add_health',
                    label: 'пособие на оздоровление (на реализ.ЗРК по Приаралью и Семей)'
                },
                {
                    key: 'tax_soc',
                    label: ' отчислен. соц налога'
                },
                {
                    key: 'tax_gfcc',
                    label: 'соц. отчислен. в ГФСС '
                },
                {
                    key: 'tax_osms',
                    label: 'отчис. обяз.соц.мед. страхов. '
                },  
                {
                    key: 'pension_contrib',
                    label: 'обязательные пенсионные взносы работодателя'
                },
                {
                    key: 'action',
                    label: ' '
                }  
            ],
            unReport: [],
            curTab: 0,
            open: false,
            headerHeight: '40px'
        };
    },

    async mounted() {
        await this.getAccessLevel();
    },
    created() {
        this.$on('fzp', (reg, reg_name, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP) => {
            this.reportOpen(reg, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP);
            this.reg = reg;
            this.year = year;
            this.qvartal = qvartal;
            this.reg_name = reg_name;
            this.dateTo = moment(date_to).format('YYYY-MM-DD');
            this.type = typeFZP;
            this.new = newFZP;
            this.rb = rbFZP;
            if (this.new) {
                this.tableFields[9].label = 'МБ (на вновь вводим. сеть)';
            } else {
                this.tableFields[9].label = 'МБ оплата труда (на дейсв. сеть)';
            }
            if (this.rb) {
                this.tableFields = [
                    {
                        key: 'num',
                        label: '№'
                    },
                    {
                        key: 'name_ru',
                        label: 'Наименование'
                    },
                    {
                        key: 'count',
                        label: 'Кол-во штатных единиц'
                    },
                    {
                        key: 'total_calc',
                        label: 'РБ - целевые трансферты (гр.4+гр.5+гр.6+гр.7+гр.8+гр.9)'
                    },
                    {
                        key: 'salary',
                        label: 'зараб. плата (110 спец и по др. спец.по отплате труда)'
                    },
                    {
                        key: 'add_village',
                        label: '25% сельских надбавок'
                    },
                    {
                        key: 'health',
                        label: 'пособие на оздоровление (Трудовой Кодекс)'
                    },
                    {
                        key: 'ecology',
                        label: 'эколог.надбавки'
                    },
                    {
                        key: 'add_vac',
                        label: 'доп. отпуск (на реализ.ЗРК по Приаралью и Семей)'
                    },
                    {
                        key: 'add_health',
                        label: 'пособие на оздоровление (на реализ.ЗРК по Приаралью и Семей)'
                    },
                    {
                        key: 'tax_soc',
                        label: ' отчислен. соц налога'
                    },
                    {
                        key: 'tax_gfcc',
                        label: 'соц. отчислен. в ГФСС '
                    },
                    {
                        key: 'tax_osms',
                        label: 'отчис. обяз.соц.мед. страхов. '
                    },  
                    {
                        key: 'pension_contrib',
                        label: 'обязательные пенсионные взносы работодателя'
                    },
                    {
                        key: 'action',
                        label: ' '
                    }  
                ]
            } else {
                if (this.type.code !== 1) {
                    this.new = false;
                    this.tableFields = [
                        {
                            key: 'num',
                            label: '№'
                        },
                        {
                            key: 'name_ru',
                            label: 'Наименование'
                        },
                        {
                            key: 'count',
                            label: 'Кол-во штатных единиц'
                        },
                        {
                            key: 'total_calc',
                            label: 'Всего по государственным служащим'
                        },
                        {
                            key: 'total_salary',
                            label: 'Заработная плата'
                        },
                        {
                            key: 'tax_soc',
                            label: 'соцналог'
                        },
                        {
                            key: 'tax_gfcc',
                            label: 'на соц. отчислен. в ГФСС'
                        },
                        {
                            key: 'tax_osms',
                            label: 'отчис. обяз.соц.мед. страхов.'
                        },
                        {
                            key: 'pension_contrib',
                            label: 'обязательные пенсионные взносы работодателей'
                        },
                        {
                            key: 'salary',
                            label: 'оплата труда'
                        },
                        {
                            key: 'health',
                            label: 'пособие на оздоровление '
                        },
                        {
                            key: 'ecology',
                            label: 'эколог.надбавки'
                        },
                        {
                            key: 'add_vac',
                            label: 'доп. отпуск (на реализ.ЗРК по Приаралью и Семей)'
                        },
                        {
                            key: 'add_health',
                            label: 'пособие на оздоровление (на реализ.ЗРК по Приаралью и Семей)'
                        },
                        {
                            key: 'action',
                            label: ' '
                        }  
                    ]
                    if (this.type.code === 3) {
                        this.tableFields[3].label = 'Всего по правоохран.органам';
                    }
                } else {
                    this.tableFields = [
                        {
                            key: 'num',
                            label: '№'
                        },
                        {
                            key: 'name_ru',
                            label: 'Наименование'
                        },
                        {
                            key: 'total_staff_count',
                            label: 'Кол-во штатных единиц'
                        },
                        {
                            key: 'total_calc',
                            label: 'ВСЕГО'
                        },
                        {
                            key: 'total_salary',
                            label: 'оплата труда'
                        },
                        {
                            key: 'total_soc_tax',
                            label: 'соцналог'
                        },
                        {
                            key: 'total_gfcc_tax',
                            label: 'на соц. отчислен. в ГФСС'
                        },
                        {
                            key: 'total_osms_tax',
                            label: 'отчис. обяз.соц.мед. страхов.'
                        },
                        {
                            key: 'total_pension_payment',
                            label: 'обязательные пенсионные взносы работодателя'
                        },
                        {
                            key: 'total_mb_salary',
                            label: 'МБ оплата труда (на дейсв. сеть)'
                        },
                        {
                            key: 'count',
                            label: 'кол-во штатных единиц'
                        },
                        {
                            key: 'salary',
                            label: 'зараб. плата (110 спец и по др. спец.по отплате труда)'
                        },
                        {
                            key: 'add_village',
                            label: '25% сельских надбавок'
                        },
                        {
                            key: 'health',
                            label: 'пособие на оздоровление (Трудовой Кодекс)'
                        },
                        {
                            key: 'ecology',
                            label: 'эколог.надбавки'
                        },
                        {
                            key: 'add_vac',
                            label: 'доп. отпуск (на реализ.ЗРК по Приаралью и Семей)'
                        },
                        {
                            key: 'add_health',
                            label: 'пособие на оздоровление (на реализ.ЗРК по Приаралью и Семей)'
                        },
                        {
                            key: 'tax_soc',
                            label: ' отчислен. соц налога'
                        },
                        {
                            key: 'tax_gfcc',
                            label: 'соц. отчислен. в ГФСС '
                        },
                        {
                            key: 'tax_osms',
                            label: 'отчис. обяз.соц.мед. страхов. '
                        },  
                        {
                            key: 'pension_contrib',
                            label: 'обязательные пенсионные взносы работодателя'
                        },
                        {
                            key: 'action',
                            label: ' '
                        }  
                    ]
                }
            }
        });
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async reportOpen(reg, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP) {
            this.loading = true;
            this.unReport = [];
            this.curParams = {
                region: reg,
                period: period,
                date_to: moment(date_to).format('YYYY-MM-DD'),
                qvartal: qvartal,
                year: year,
                report_id: this.report_id,
                form: this.repCode,
                obl: obl,
                uuid: uuid,
                type: typeFZP.code,
                new: newFZP,
                report: false,
                rb: rbFZP
            };
            Ax(
                {
                    url: `/api-py/get-fulldata-fzp/` + JSON.stringify(this.curParams),
                    method: 'GET'
                },
                (data) => {
                    const total = {};
                    if (data.unReport === 0){
                        this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
                    } else {
                        const grList = Object.keys(data.unReport);
                        total.name_ru = 'Всего';
                        total.visible = true;
                        total.count = 0;
                        total.tax_soc = 0;
                        total.tax_gfcc = 0;
                        total.tax_osms = 0;
                        total.pension_contrib = 0;
                        total.salary = 0;
                        total.health = 0;
                        total.ecology = 0;
                        total.add_vac = 0;
                        total.add_health = 0;
                        total.add_village = 0;
                        total.total_mb_salary = 0;
                        total.total_pension_payment = 0;
                        total.total_osms_tax = 0;
                        total.total_gfcc_tax = 0;
                        total.total_soc_tax = 0;
                        total.total_salary = 0;
                        total.total_calc = 0;
                        total.total_staff_count = 0;
                        total._rowVariant = 'white';
                        for (const i of grList) {
                            const gr = data.unReport[i];
                            const pgrList = Object.keys(gr.pgr);
                            const grItem = {
                                'gr': gr.gr,
                                'dataType': 1,
                                'name_ru': `${gr.gr} - ${gr.name_ru}`,
                                'count': 0,
                                'tax_soc': 0,
                                'tax_gfcc': 0,
                                'tax_osms': 0,
                                'pension_contrib': 0,
                                'salary': 0,
                                'health': 0,
                                'ecology': 0,
                                'add_vac': 0,
                                'add_health': 0,
                                'add_village': 0,
                                _rowVariant: 'info',
                                'open': this.open,
                                'visible': true
                            }
                            this.unReport.push(grItem);
                            for (const x of pgrList) {
                                const pgr = gr.pgr[x];
                                const abpList = Object.keys(pgr.abp);
                                const pgrItem = {
                                    'gr': gr.gr,
                                    'pgr': pgr.pgr,
                                    'dataType': 2,
                                    'name_ru': pgr.name_ru,
                                    'count': 0,
                                    'tax_soc': 0,
                                    'tax_gfcc': 0,
                                    'tax_osms': 0,
                                    'pension_contrib': 0,
                                    'salary': 0,
                                    'health': 0,
                                    'ecology': 0,
                                    'add_vac': 0,
                                    'add_health': 0,
                                    'add_village': 0,
                                    _rowVariant: 'secondary',
                                    'open': this.open
                                }
                                this.unReport.push(pgrItem)
                                for (const a of abpList) {
                                    const abp = pgr.abp[a];
                                    const abpItem = {
                                        'id': abp.id,
                                        'gr': gr.gr,
                                        'pgr': pgr.pgr,
                                        'abp': abp.abp,
                                        'dataType': 3,
                                        'changed': false,
                                        'name_ru': `${abp.abp} - ${abp.name_ru}`,
                                        'count': !newFZP ? abp.count : abp.new_count,
                                        'tax_soc': !newFZP ? abp.tax_soc: abp.new_tax_soc,
                                        'tax_gfcc': !newFZP ? abp.tax_gfcc : abp.new_tax_gfcc,
                                        'tax_osms': !newFZP ? abp.tax_osms : abp.new_tax_osms,
                                        'pension_contrib': !newFZP ? abp.pension_contrib : abp.new_pension_contrib,
                                        'salary': !newFZP ? abp.salary : abp.new_salary,
                                        'health': !newFZP ? abp.health : abp.new_health,
                                        'ecology': !newFZP ? abp.ecology : abp.new_ecology,
                                        'add_vac': !newFZP ? abp.add_vac : abp.new_add_vac,
                                        'add_health': !newFZP ? abp.add_health : abp.new_add_health,
                                        'add_village': !newFZP ? abp.add_village : abp.new_add_village,
                                        'new_salary': !newFZP ? abp.new_salary : abp.salary,
                                        'new_add_village': !newFZP ? abp.new_add_village : abp.add_village,
                                        'new_health': !newFZP ? abp.new_health : abp.health,
                                        'new_ecology': !newFZP ? abp.new_ecology : abp.ecology,
                                        'new_add_vac': !newFZP ? abp.new_add_vac : abp.add_vac,
                                        'new_add_health': !newFZP ? abp.new_add_health : abp.add_health,
                                        'new_pension_contrib': !newFZP ? abp.new_pension_contrib : abp.pension_contrib,
                                        'new_tax_osms': !newFZP ? abp.new_tax_osms : abp.tax_osms,
                                        'new_tax_gfcc': !newFZP ? abp.new_tax_gfcc : abp.tax_gfcc,
                                        'new_tax_soc': !newFZP ? abp.new_tax_soc : abp.tax_soc,
                                        'new_count': !newFZP ? abp.new_count : abp.count,
                                        _rowVariant: 'white',
                                        'showErr': false
                                    }
                                    this.unReport.push(abpItem);
                                }
                            }
                        }
                    }
                    for (const item of this.unReport) {
                        if (item.dataType !== 3) {
                            item.count = 0;
                            item.tax_soc = 0;
                            item.tax_gfcc = 0;
                            item.tax_osms = 0;
                            item.pension_contrib = 0;
                            item.salary = 0;
                            item.health = 0;
                            item.ecology = 0;
                            item.add_vac = 0;
                            item.add_health = 0;
                            item.add_village = 0;
                            item.total_mb_salary = 0;
                            item.total_pension_payment = 0;
                            item.total_osms_tax = 0;
                            item.total_gfcc_tax = 0;
                            item.total_soc_tax = 0;
                            item.total_salary = 0;
                            item.total_calc = 0;
                            item.total_staff_count = 0;
                        }
                        if (item.abp) {
                            item.total_new_mb_salary = Number(item.new_salary) + Number(item.new_add_village) + Number(item.new_health) + Number(item.new_ecology) + Number(item.new_add_vac) + Number(item.new_add_health);
                            item.total_mb_salary = Number(item.salary) + Number(item.add_village) + Number(item.health) + Number(item.ecology) + Number(item.add_vac) + Number(item.add_health);
                            item.total_pension_payment = Number(item.pension_contrib) + Number(item.new_pension_contrib);
                            item.total_osms_tax = Number(item.tax_osms) + Number(item.new_tax_osms);
                            item.total_gfcc_tax = Number(item.tax_gfcc) + Number(item.new_tax_gfcc);
                            item.total_soc_tax = Number(item.tax_soc) + Number(item.new_tax_soc);
                            item.total_add_village = Number(item.add_village) + Number(item.new_add_village)
                            item.total_health = Number(item.health) + Number(item.new_health)
                            item.total_ecology = Number(item.ecology) + Number(item.new_ecology)
                            item.total_add_vac = Number(item.add_vac) + Number(item.new_add_vac)
                            item.total_add_health = Number(item.add_health) + Number(item.new_add_health)
                            
                            if (this.rb) {
                                item.total_salary = Number(item.salary) + Number(item.new_salary);
                                item.total_calc = item.total_mb_salary + item.total_new_mb_salary;
                            } else {
                                item.total_salary = item.total_mb_salary + item.total_new_mb_salary;
                                item.total_calc = item.total_salary + item.total_soc_tax + item.total_gfcc_tax + item.total_osms_tax + item.total_pension_payment;
                            }
                            item.total_staff_count = Number(item.count) + Number(item.new_count);
                            const gr = this.unReport.find(x => x.gr === item.gr && x.dataType === 1);
                            total.count += Number(item.count);
                            gr.count += Number(item.count);
                            total.tax_soc += Number(item.tax_soc);
                            gr.tax_soc += Number(item.tax_soc);
                            total.tax_gfcc += Number(item.tax_gfcc);
                            gr.tax_gfcc += Number(item.tax_gfcc);
                            total.tax_osms += Number(item.tax_osms);
                            gr.tax_osms += Number(item.tax_osms);
                            total.pension_contrib += Number(item.pension_contrib);
                            gr.pension_contrib += Number(item.pension_contrib);
                            total.salary += Number(item.salary);
                            gr.salary += Number(item.salary);
                            total.health += Number(item.health);
                            gr.health += Number(item.health);
                            total.ecology += Number(item.ecology);
                            gr.ecology += Number(item.ecology);
                            total.add_vac += Number(item.add_vac);
                            gr.add_vac += Number(item.add_vac);
                            total.add_health += Number(item.add_health);
                            gr.add_health += Number(item.add_health);
                            total.add_village += Number(item.add_village);
                            gr.add_village += Number(item.add_village);
                            total.total_mb_salary += Number(item.total_mb_salary);
                            gr.total_mb_salary += Number(item.total_mb_salary);
                            total.total_pension_payment += Number(item.total_pension_payment);
                            gr.total_pension_payment += Number(item.total_pension_payment);
                            total.total_osms_tax += Number(item.total_osms_tax);
                            gr.total_osms_tax += Number(item.total_osms_tax);
                            total.total_gfcc_tax += Number(item.total_gfcc_tax);
                            gr.total_gfcc_tax += Number(item.total_gfcc_tax);
                            total.total_soc_tax += Number(item.total_soc_tax);
                            gr.total_soc_tax += Number(item.total_soc_tax);
                            total.total_salary += Number(item.total_salary);
                            gr.total_salary += Number(item.total_salary);
                            total.total_calc += Number(item.total_calc);
                            gr.total_calc += Number(item.total_calc);
                            total.total_staff_count += Number(item.total_staff_count);
                            gr.total_staff_count += Number(item.total_staff_count);
                            const pgr = this.unReport.find(x => (x.gr === item.gr) && (x.pgr === item.pgr) && (x.dataType === 2));
                            pgr.count += Number(item.count);
                            pgr.tax_soc += Number(item.tax_soc);
                            pgr.tax_gfcc += Number(item.tax_gfcc);
                            pgr.tax_osms += Number(item.tax_osms);
                            pgr.pension_contrib += Number(item.pension_contrib);
                            pgr.salary += Number(item.salary);
                            pgr.health += Number(item.health);
                            pgr.ecology += Number(item.ecology);
                            pgr.add_vac += Number(item.add_vac);
                            pgr.add_health += Number(item.add_health);
                            pgr.add_village += Number(item.add_village);
                            pgr.total_mb_salary += Number(item.total_mb_salary);
                            pgr.total_pension_payment += Number(item.total_pension_payment);
                            pgr.total_osms_tax += Number(item.total_osms_tax);
                            pgr.total_gfcc_tax += Number(item.total_gfcc_tax);
                            pgr.total_soc_tax += Number(item.total_soc_tax);
                            pgr.total_salary += Number(item.total_salary);
                            pgr.total_calc += Number(item.total_calc);
                            pgr.total_staff_count += Number(item.total_staff_count);
                        }
                    }
                    this.unReport.unshift(total);
                    this.curTab = 0;
                    this.loading = false;
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString());
                    this.loading = false;
                }
            )
        },
        async saveData(item) {
            this.updateData({});
            const saveList = [];
            let stopSave = false;
            for (const data of this.unReport.filter(x => x.changed && x.gr === item.gr)) {
                if (data.showErr) {
                    stopSave = true;
                    this.makeToast('danger', 'Внимание!', 'Заполните информацию по количеству штатных единиц');
                    break;
                } 
                const d = {
                    'id': data.id,
                    'region': this.curParams.region,
                    'date': this.curParams.date_to,
                    'gr': data.gr,
                    'pgr': data.pgr,
                    'abp': data.abp,
                    'type': this.type.code,
                    'count': data.count,
                    'tax_soc': data.tax_soc,
                    'tax_gfcc': data.tax_gfcc,
                    'tax_osms': data.tax_osms,
                    'pension_contrib': data.pension_contrib,
                    'salary': data.salary,
                    'health': data.health,
                    'ecology': data.ecology,
                    'add_vac': data.add_vac,
                    'add_health': data.add_health,
                    'add_village': data.add_village,
                    'new': this.new,
                    'user_id': this.$store.getters.user_uuid,
                    'rb': this.rb
                }
                saveList.push(d)
            }
            if (!stopSave) {
                const response = await fetch('/api-py/fulfillment-report/fzp/data/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveList)
                });
                if (response.status === 200) {
                    const data = await response.json();
                    this.makeToast('success', 'Сохранение', 'Данные успешно сохранены');
                    for (const d of data.id_list) {
                        const match = this.unReport.find(x => (x.gr === d.gr) && (x.pgr === d.pgr) && (x.abp === d.abp));
                        if (match) {
                            match.id = d.id;
                            match.changed = false;
                        } 
                    }
                }
            }
        },
        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        downloadRep() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.isLoad = true
                this.curParams.report = true;
                Ax(
                    {
                        url: '/api-py/fulfill_report_fzp/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
            } finally {
                this.isLoad = false
            }
        },
        checkPos(item) {
            for (const key of Object.keys(item).entries()) {
                if (typeof item[key[1]] === 'number' && item[key[1]] < 0) {
                    item[key[1]] *= -1;
                }
                if ((key[0] > 7) && (key[0] < 18)) {
                    if ((item.count === 0) && (item[key[1]] > 0)) {
                        item.showErr = true;
                    } else {
                        item.showErr = false;
                    }
                }
            }
            for (const key of Object.keys(item).entries()) {
                if ((key[0] > 7) && (key[0] < 18)) {
                    console.log(item.showErr)
                    if ((item.count === 0) && (item[key[1]] > 0)) {
                        item.showErr = true;
                        break;
                    } else {
                        item.showErr = false;
                    }
                }
            }
            return item;
        },
        updateData(dat) {
            const data = this.checkPos(dat);
            data.changed = true;
            const total = this.unReport[0];
            total.name_ru = 'Всего'
            total.count = 0;
            total.tax_soc = 0;
            total.tax_gfcc = 0;
            total.tax_osms = 0;
            total.pension_contrib = 0;
            total.salary = 0;
            total.health = 0;
            total.ecology = 0;
            total.add_vac = 0;
            total.add_health = 0;
            total.add_village = 0;
            total.total_mb_salary = 0;
            total.total_pension_payment = 0;
            total.total_osms_tax = 0;
            total.total_gfcc_tax = 0;
            total.total_soc_tax = 0;
            total.total_salary = 0;
            total.total_calc = 0;
            total.total_staff_count = 0;
            for (const item of this.unReport) {
                if (item.dataType !== 3) {
                    item.count = 0;
                    item.tax_soc = 0;
                    item.tax_gfcc = 0;
                    item.tax_osms = 0;
                    item.pension_contrib = 0;
                    item.salary = 0;
                    item.health = 0;
                    item.ecology = 0;
                    item.add_vac = 0;
                    item.add_health = 0;
                    item.add_village = 0;
                    item.total_mb_salary = 0;
                    item.total_pension_payment = 0;
                    item.total_osms_tax = 0;
                    item.total_gfcc_tax = 0;
                    item.total_soc_tax = 0;
                    item.total_salary = 0;
                    item.total_calc = 0;
                    item.total_staff_count = 0;
                }
                if (item.abp) {
                    item.count = Number(item.count);
                    item.tax_soc = Number(item.tax_soc);
                    item.tax_gfcc = Number(item.tax_gfcc);
                    item.tax_osms = Number(item.tax_osms);
                    item.pension_contrib = Number(item.pension_contrib);
                    item.salary = Number(item.salary);
                    item.health = Number(item.health);
                    item.ecology = Number(item.ecology);
                    item.add_vac = Number(item.add_vac);
                    item.add_health = Number(item.add_health);
                    item.add_village = Number(item.add_village);
                    item.total_new_mb_salary = Number(item.new_salary) + Number(item.new_add_village) + Number(item.new_health) + Number(item.new_ecology) + Number(item.new_add_vac) + Number(item.new_add_health);
                    item.total_mb_salary = Number(item.salary) + Number(item.add_village) + Number(item.health) + Number(item.ecology) + Number(item.add_vac) + Number(item.add_health);
                    item.total_pension_payment = Number(item.pension_contrib) + Number(item.new_pension_contrib);
                    item.total_osms_tax = Number(item.tax_osms) + Number(item.new_tax_osms);
                    item.total_gfcc_tax = Number(item.tax_gfcc) + Number(item.new_tax_gfcc);
                    item.total_soc_tax = Number(item.tax_soc) + Number(item.new_tax_soc);
                    item.total_salary = item.total_mb_salary + item.total_new_mb_salary;
                    item.total_calc = item.total_salary + item.total_soc_tax + item.total_gfcc_tax + item.total_osms_tax + item.total_pension_payment;
                    item.total_staff_count = Number(item.count) + Number(item.new_count);
                    const gr = this.unReport.find(x => x.gr === item.gr && x.dataType === 1);
                    total.count += Number(item.count);
                    gr.count += Number(item.count);
                    total.tax_soc += Number(item.tax_soc);
                    gr.tax_soc += Number(item.tax_soc);
                    total.tax_gfcc += Number(item.tax_gfcc);
                    gr.tax_gfcc += Number(item.tax_gfcc);
                    total.tax_osms += Number(item.tax_osms);
                    gr.tax_osms += Number(item.tax_osms);
                    total.pension_contrib += Number(item.pension_contrib);
                    gr.pension_contrib += Number(item.pension_contrib);
                    total.salary += Number(item.salary);
                    gr.salary += Number(item.salary);
                    total.health += Number(item.health);
                    gr.health += Number(item.health);
                    total.ecology += Number(item.ecology);
                    gr.ecology += Number(item.ecology);
                    total.add_vac += Number(item.add_vac);
                    gr.add_vac += Number(item.add_vac);
                    total.add_health += Number(item.add_health);
                    gr.add_health += Number(item.add_health);
                    total.add_village += Number(item.add_village);
                    gr.add_village += Number(item.add_village);
                    total.total_mb_salary += Number(item.total_mb_salary);
                    gr.total_mb_salary += Number(item.total_mb_salary);
                    total.total_pension_payment += Number(item.total_pension_payment);
                    gr.total_pension_payment += Number(item.total_pension_payment);
                    total.total_osms_tax += Number(item.total_osms_tax);
                    gr.total_osms_tax += Number(item.total_osms_tax);
                    total.total_gfcc_tax += Number(item.total_gfcc_tax);
                    gr.total_gfcc_tax += Number(item.total_gfcc_tax);
                    total.total_soc_tax += Number(item.total_soc_tax);
                    gr.total_soc_tax += Number(item.total_soc_tax);
                    total.total_salary += Number(item.total_salary);
                    gr.total_salary += Number(item.total_salary);
                    total.total_calc += Number(item.total_calc);
                    gr.total_calc += Number(item.total_calc);
                    total.total_staff_count += Number(item.total_staff_count);
                    gr.total_staff_count += Number(item.total_staff_count);
                    const pgr = this.unReport.find(x => (x.gr === item.gr) && (x.pgr === item.pgr) && (x.dataType === 2));
                    pgr.count += Number(item.count);
                    pgr.tax_soc += Number(item.tax_soc);
                    pgr.tax_gfcc += Number(item.tax_gfcc);
                    pgr.tax_osms += Number(item.tax_osms);
                    pgr.pension_contrib += Number(item.pension_contrib);
                    pgr.salary += Number(item.salary);
                    pgr.health += Number(item.health);
                    pgr.ecology += Number(item.ecology);
                    pgr.add_vac += Number(item.add_vac);
                    pgr.add_health += Number(item.add_health);
                    pgr.add_village += Number(item.add_village);
                    pgr.total_mb_salary += Number(item.total_mb_salary);
                    pgr.total_pension_payment += Number(item.total_pension_payment);
                    pgr.total_osms_tax += Number(item.total_osms_tax);
                    pgr.total_gfcc_tax += Number(item.total_gfcc_tax);
                    pgr.total_soc_tax += Number(item.total_soc_tax);
                    pgr.total_salary += Number(item.total_salary);
                    pgr.total_calc += Number(item.total_calc);
                    pgr.total_staff_count += Number(item.total_staff_count);
                }
            }
            this.unReport[0] = total;
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.unReport.filter(x => x.dataType)) {
                if (r.dataType !== 3) {
                    r.open = this.open;
                }
                if (r.dataType !== 1) {
                    r.visible = this.open;
                }
            }
        },  
        openChilds(item) {
            item.open = !item.open;
            if (item.dataType === 1) {
                if (!item.open) {
                    for (const r of this.unReport.filter(x => x.gr === item.gr && x.dataType !== 1)) {
                        r.open = item.open;
                        r.visible = item.open;
                    }
                } else {
                    for (const r of this.unReport.filter(x => (x.gr === item.gr) && (x.dataType === 2))) {
                        r.visible = item.open;
                    }
                }
            }
            if (item.dataType === 2) {
                for (const r  of this.unReport.filter(x => (x.gr === item.gr) && (x.pgr === item.pgr) && (x.dataType === 3))) {
                    r.visible = item.open;
                }
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
    }
}
</script>

<style scoped>
.td-numbering{
    padding: 3px 10px!important;
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 400!important;
    color: #6087A0 !important;
    background-color: #F7F9FC;
}
</style>