<template>
    <div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <br>
                    <b-form-group label="Наименование отчета " class="bolded-label">
                        <multiselect
                            v-model="curReport"
                            :options="reportList"
                            track-by="code"
                            label="name_ru"
                            placeholder="Выберите отчет"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="callFilterGetObl"
                        >
                        </multiselect>
                    </b-form-group>
                    <filter-all v-if="curReport != null"
                                :repCode="curReport"
                                ref="filter"
                                @fzp="reportFzp"
                                @budgetMsu="reportBudgetMsu"

                    >
                    </filter-all>
                </div>
            </b-dropdown>
            <b-button v-if="curReport && curReport.code === 'budget-msu' && (this.access_level === 2 || this.access_level === 3)"
                      :variant="buttonVar"
                      :disabled="!isSave"
                      @click="repSaveBudgetMsu">Сохранить</b-button>
        </div>
        <br><br>
        <div>
            <application-fzp v-if="curReport && curReport.code === 'FZP'" ref="fzp"></application-fzp>
            <report-budget-msu v-if="curReport && curReport.code === 'budget-msu'" @reasonChanged="checkIsSave" ref="budgetMsu"></report-budget-msu>
        </div>
    </div>
</template>

<script>
import filterAll from "@/modules/budget/monitoring/report-components/filter";
import applicationFzp from "@/modules/budget/monitoring/report-components/application-form-fzp.vue";
import reportBudgetMsu from '@/modules/budget/monitoring/report-components/application-form-budget-msu.vue'

export default {
    components: {filterAll, applicationFzp, reportBudgetMsu},
    name: 'report-fulfill',
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() - 1,
            reportList: {},
            curReport: null,
            isOpen: false,
            access_level: 1,
            padding: {},
            isSave: false,
            version: 0,
        };
    },

    async mounted() {
        await this.getReportList();
        await this.getAccessLevel();
    },
    computed: {
        buttonVar() {
            return this.isSave ? "success" : "secondary";
        }
    },
    methods: {
        async getAccessLevel() {
            try {
                const response = await fetch(`/api-py/user-modules/${this.$store.getters.user_uuid}/005.003.006`);

                const modules_access = await response.json();
                if (modules_access.length > 1) {
                    this.makeToast("danger", "Ошибка", "У пользователя несколько записей")
                }
                if (modules_access.length === 0) {
                    this.access_level = 1;
                } else {
                    for (const ma of modules_access) {
                        this.access_level = ma.access_level;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса load_user_access_level', error.toString());
            }
        },
        updateIsSave() {
            this.isSave = true;
        },
        repSaveBudgetMsu() {
            this.$refs.budgetMsu.repSave();
        },
        checkIsSave(check) {
            this.isSave = check;
        },
        callFilterGetObl() {
            if (this.curReport !== null && this.$refs.filter){
                this.$refs.filter.getObl();
                this.$refs.filter.period = '';
                this.$refs.filter.currYear = 2023;
            }
        },
        async getReportList() {
            const response = await fetch(`/api-py/get-plan-report-list`);
            this.reportList = await response.json();
        },
        reportFzp(reg, reg_name, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP){
            this.$refs.fzp.headerHeight = '0px'
            this.$refs.fzp.$emit('fzp', reg,  reg_name, period, date_to, qvartal, year, obl, uuid, typeFZP, newFZP, rbFZP);
        },
        reportBudgetMsu(reg, reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month){
            this.$refs.budgetMsu.headerHeight = '0px'
            this.$refs.budgetMsu.$emit('budgetMsu', reg,  reg_name, period, date_to, qvartal, year, obl, uuid, sheet, month);
        },
        repOpen(data) {
            this.isOpen = data;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

    },
};
</script>